import { format, parse, parseISO, parseJSON, isValid } from 'date-fns';
import { formatInTimeZone  } from 'date-fns-tz';

String.prototype.ucfirst = function(): string {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ucwords = function(): string {
    const words = this.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    return words.join(" ");
};

Array.prototype.filterUnique = function(propertyName) {
    return this.filter((e, i) => this.findIndex(a => a && e && a[propertyName] === e[propertyName]) === i);
};

Array.prototype.distinct = function() {
    return this.filter(function(value, index, self) {
        return self.indexOf(value) === index;
    })
}

Array.prototype.moreThoroughDistinct = function () {
    return this.filter((value, index, self) => {
        const _value = JSON.stringify(value);
        return index === self.findIndex(obj => {
            return JSON.stringify(obj) === _value;
        });
    });
}

Array.prototype.flatten = function() {
    return [].concat.apply([], this);
};

Array.prototype.sortBy = function(type) {
    return this.sort((a, b) => {
        let nameA = a[type].toUpperCase(),
            nameB = b[type].toUpperCase();

        if (nameA < nameB) return -1;
            
        if (nameA > nameB) return 1;

        return 0;
    });
};

Array.prototype.min = function() {
    return Math.min.apply(Math, this);
};

Array.prototype.max = function() {
    return Math.max.apply(Math, this);
};

Array.prototype.sum = function(prop) {
    if (!this.length)
        return 0;

    if (!prop)
        return this.reduce((a, b) => a + b);

    if (prop)
        return this.reduce((a, b) => a + (b[prop] || 0), 0);
};

Array.prototype.chunk = function(size) {
    const chunked_arr = [];
    let copied = this.slice(0);//JSON.parse(JSON.stringify(array)); 
    const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer

    for (let i = 0; i < numOfChild; i++) {
        chunked_arr.push(copied.splice(0, size));
    }

    return chunked_arr;
};

Array.prototype.diff = function(arr2) {
    var ret = [];
    this.sort();
    arr2.sort();

    for(var i = 0; i < this.length; i += 1) {
        if (arr2.indexOf(this[i]) > -1){
            ret.push(this[i]);
        }
    }
    
    return ret;
};

Array.prototype.pluck = function(key) {
    return this.map(object => object[key]);
};

Array.prototype.pluckRandom = function() {
    return this[Math.floor(Math.random() * this.length)]
};

Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';

    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');

    // 1234..format();           // "1,234"
    // 12345..format(2);         // "12,345.00"
    // 123456.7.format(3, 2);    // "12,34,56.700"
    // 123456.789.format(2, 4);  // "12,3456.79"
};

String.prototype.utoa = function(): string {
    return btoa(unescape(encodeURIComponent(String(this))));
};

String.prototype.atou = function(): string {
    return decodeURIComponent(escape(atob(String(this))));
};

String.prototype.ucwords = function(): string {
    let str = this.toLowerCase();
    
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, (string) => string.toLocaleUpperCase());
};

String.prototype.truncate = function(limit = 255, ellipsis = true): string {
    return this.slice(0, limit) + (ellipsis && this.length > limit ? "..." : "")
}

Number.prototype.round = function(decimals = 0) {
    return Number(Math.round(this + 'e' + decimals) + 'e-' + decimals);
};

Array.prototype.first = function() {
    return this[0];
};

Array.prototype.last = function() {
    return this[this.length - 1];
};

Array.prototype.shuffle = function():[] {
    let array:[] = Array.from(this);

    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
};

Array.prototype.countValues = function() {
    // https://stackoverflow.com/a/24980329/1380921
    return this.reduce(function(acc, e) { acc[e] = (e in acc ? acc[e] + 1 : 1); return acc}, {});
}

String.prototype.gradeToString = function(): string {
    // numeric grades are often passed around as strings too 
    if (this == "0") return 'k';
    if (this == "alg1") return 'ALG1'
    if (this == "geo") return 'GEO'

    return this.toString();
}

Number.prototype.gradeToString = function(): string {
    if (this == 0) return 'k';

    return this.toString();
};

String.prototype.spaceToHyphen = function(): string {
    return this.replaceAll(' ', '-');
};

String.prototype.hyphenToSpace = function(): string {
    return this.replaceAll('-', ' ');
};

String.prototype.camelCaseToHyphen = function(): string {
    return this.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase());
};

String.prototype.camelCaseToTitle = function(): string {
    return this.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? ' ' : '') + match.toLowerCase());
};

Date.prototype.formatMMDDYYYY = function(){
    return (this.getMonth() + 1) + 
    "/" +  this.getDate() +
    "/" +  this.getFullYear();
}

Number.prototype.decimalLength = function(value) {
    if (Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;

    return 0;
}

Array.prototype.intersect = function(incoming: []): any {
    return this.filter(function(n: any) {
        return incoming.findIndex((element) => element == n) !== -1;
    });
}

String.prototype.reverseString = function(): string {
    let splitString = this.split(""); 
    let reverseArray = splitString.reverse();
    let joinArray = reverseArray.join("");

    return joinArray;
}

/**
 * Formats the string as a date based on the given format, with optional localization and time zone handling.
 * 
 * @param displayFormat - The format string to display the date. Defaults to 'MM/dd/yyyy'.
 * @param localize - Boolean indicating if the date should be localized. Defaults to `false`.
 * @param timeZone - The time zone to use for localization. Only used if `localize` is `true`.
 * @returns A formatted date string based on the input and format, or an empty string if the date is invalid.
 *
 * @example
 * '2024-08-22T00:00:00Z'.formatDate(); // Returns '08/22/2024'
 * '2024-08-22T00:00:00Z'.formatDate('yyyy-MM-dd', true, 'Europe/Paris'); // Returns '2024-08-22' localized to Paris time
 */
String.prototype.formatDate = function(this: string, displayFormat = 'MM/dd/yyyy', localize = false, timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string {
    let formattedDate: Date;

    try {
        // Detect if the string is a TIMESTAMP (format: YYYY-MM-DD HH:mm:ss)
        const timestampRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

        if (timestampRegex.test(this.trim())) {
            // Use parseJSON for TIMESTAMP columns
            formattedDate = parseJSON(this.trim());
        } else {
            // Use parseISO for generic dates, do not append 'Z'
            formattedDate = parseISO(this.trim());
        }

        // Check if the parsed date is valid
        if (!isValid(formattedDate)) {
            return '';
        }

        // If localization is requested, use formatInTimeZone for proper timezone handling
        if (localize)
            return formatInTimeZone(formattedDate, timeZone, displayFormat);

        // Return the formatted date string using date-fns format function
        return format(formattedDate, displayFormat);
    } catch (e) {
        console.log('error', e);
        return '';
    }
};

String.prototype.formatTime = function(this: string, timeFormat = 'h:mm a'): string {
    let formattedTime: Date;

    try {
        // Parse the string as a time in 'HH:mm:ss' format
        formattedTime = parse(this, 'HH:mm:ss', new Date());

        // Check if the parsed time is valid
        if (!isValid(formattedTime)) return '';

        // Return the formatted time string
        return format(formattedTime, timeFormat);
    } catch (e) {
        return '';
    }
};

export function parseTime(timeString: string): Date {
    // If two colons are found, use HH:mm:ss format, otherwise use HH:mm format
    const timeFormat = (timeString.match(/:/g) || []).length === 2 ? 'HH:mm:ss' : 'HH:mm';

    return parse(timeString, timeFormat, new Date());
}

export function resolveLanguageName(lang: string): string {
    if (lang == 'es')
        return 'Spanish';

    if (lang == 'en')
        return 'English';

    return '--'; // Error ocurred and need to find user language
}

export function fullGrade(grade: number|string, shownInTitle = false): string {
    if(grade === 0 || grade === 'k')
        return 'Kindergarten';

    if(grade == 1)
        return '1st Grade';

    if(grade == 2)
        return '2nd Grade';

    if(grade == 3)
        return '3rd Grade';

    if(grade == 11 && shownInTitle)
        return '11th Grade Claim 1: Concepts and Procedures';

    if(grade == 11)
        return '11th Grade Claim 1';

    if(grade == 'alg1')
        return 'Algebra 1';

    if(grade == 'geo')
        return 'Geometry';

    return `${grade}th Grade`;
}

export function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number = 300) {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }

        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function arrayDot(obj: any, prefix: string = ''): { [key: string]: any } {
    return Object.keys(obj).reduce((acc: { [key: string]: any }, key: string) => {
        const pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(acc, arrayDot(obj[key], pre + key));
        } else {
            acc[pre + key] = obj[key];
        }
        return acc;
    }, {});
}

export function arrayUnDot(object: any, path: string, value: any): void {
    return path.split('.')
        .reduce((o: any, p: string, i: number, arr: string[]) => {
            return o[p] = arr.length === i + 1 ? value : o[p] || {};
        }, object);
}

export function pluralize(count: number, noun: string, suffix: string = 's'): string {
    return `${noun}${count !== 1 ? suffix : ''}`;
}

export function convertToHTMLEntities(str: string): string {
    const entities: { [key: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
    };

    return str.replace(/[&<>"']/g, function (char) {
        return entities[char];
    });
}

export function convertFromHTMLEntities(str: string): string {
    const entities: { [key: string]: string } = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
    };

    return str.replace(/&amp;|&lt;|&gt;|&quot;|&#39;/g, function (entity) {
        return entities[entity];
    });
}

